// src/pages/RecoveryPage.js
import React from 'react';
import Recovery from '../components/Auth/Recovery';

const RecoveryPage = () => {
  return (
    <div>
      <Recovery />
    </div>
  );
};

export default RecoveryPage;
