// src/components/LandingPage/FeaturesSection.js
import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

const features = [
  "High-quality PDF conversion",
  "Easy to use interface",
  "Fast and reliable",
  "Secure and private",
];

const FeaturesSection = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Typography variant="h4" component="h2" gutterBottom align="center">
          Our Features
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Box display="flex" alignItems="center">
                <CheckCircleOutline color="primary" />
                <Typography variant="h6" component="p" sx={{ ml: 2 }}>
                  {feature}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FeaturesSection;
