// src/pages/WebhookPage.js
import React from 'react';
import Webhook from '../components/Webhooks/Webhook';

const WebhookPage = () => {
  return (
    <div>
      <Webhook />
    </div>
  );
};

export default WebhookPage;
