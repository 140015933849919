// src/components/Auth/Recovery.js
import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Box } from '@mui/material';

const Recovery = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Lógica para manejar la recuperación de contraseña
    console.log('Email:', email);
  };

  return (
    <Container maxWidth="xs">
      <Box mt={8}>
        <Typography variant="h5" align="center">Recover Password</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 2 }}>
            Recover
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Recovery;
