// src/App.js
import React from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import Home from './pages/Home'
import LoginPage from './pages/LoginPage'
import RegisterPage from './pages/RegisterPage'
import RecoveryPage from './pages/RecoveryPage'
import BalancePage from './pages/BalancePage'
import TokenPage from './pages/TokenPage'
import WebhookPage from './pages/WebhookPage'
import Navbar from './components/Shared/Navbar'
import Footer from './components/Shared/Footer'
import AuthProvider, { AuthContext } from './contexts/AuthContext'
import './App.css'

const PrivateRoute = ({ children }) => {
  const { user } = React.useContext(AuthContext)
  // user = null
  // console.log(user)
  return user || true ? children : <Navigate to="/login" />
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <CssBaseline />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/recover" element={<RecoveryPage />} />
          <Route path="/balance" element={
            <PrivateRoute>
              <BalancePage />
            </PrivateRoute>
          } />
          <Route path="/tokens" element={
            <PrivateRoute>
              <TokenPage />
            </PrivateRoute>
          } />
          <Route path="/webhooks" element={
            <PrivateRoute>
              <WebhookPage />
            </PrivateRoute>
          } />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  )
}

export default App
