// src/components/LandingPage/WebhookIntegrationSection.js
import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Webhook as WebhookIcon, CheckCircleOutline } from '@mui/icons-material';

const WebhookIntegrationSection = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Typography variant="h4" component="h2" gutterBottom align="center">
          Integrate via Webhook
        </Typography>
        <Typography variant="h6" paragraph align="center">
          Automate your PDF conversion workflow by integrating with our webhook. Once the conversion is done, we will send the PDF to your specified endpoint.
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <WebhookIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Easily set up your webhook URL in your account settings." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutline color="primary" />
            </ListItemIcon>
            <ListItemText primary="Receive a notification with the PDF directly to your endpoint once the conversion is complete." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutline color="primary" />
            </ListItemIcon>
            <ListItemText primary="Ensure secure and reliable delivery of your converted documents." />
          </ListItem>
        </List>
      </Container>
    </Box>
  );
};

export default WebhookIntegrationSection;
