// src/components/Shared/Navbar.js
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Toolbar, Typography, Button } from '@mui/material'
import { AuthContext } from '../../contexts/AuthContext'
import DarkModeToggle from '../DarkModeToggle'


const Navbar = () => {
  const { user, logout } = useContext(AuthContext)

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          MyApp
        </Typography>
        <Button color="inherit" component={Link} to="/">Home</Button>
        {user ? (
          <>
            <Button color="inherit" component={Link} to="/balance">Balance</Button>
            <Button color="inherit" component={Link} to="/tokens">Tokens</Button>
            <Button color="inherit" component={Link} to="/webhooks">Webhooks</Button>
            <Button color="inherit" onClick={logout}>Logout</Button>
          </>
        ) : (
          <>
            <Button color="inherit" component={Link} to="/login">Login</Button>
            <Button color="inherit" component={Link} to="/register">Register</Button>
          </>
        )}
        <DarkModeToggle />
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
