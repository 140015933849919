// src/pages/TokenPage.js
import React from 'react';
import Token from '../components/Tokens/Token';

const TokenPage = () => {
  return (
    <div>
      <Token />
    </div>
  );
};

export default TokenPage;
