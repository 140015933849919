// src/components/Tokens/Token.js
import React, { useEffect, useState } from 'react'
import { Container, IconButton, Box, TextField, Button, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material'


import { Delete, Visibility, ContentCopy } from '@mui/icons-material'
import { create, remove, list } from '../../services/tokenService'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

const Token = () => {
  //const [error, setError] = useState('')
  //const [success, setSuccess] = useState('')

  // dialog create
  const [dialogCreate, setDialogCreate] = useState(false)
  const [creating, setCreating] = useState(false)

  // dialog delete
  const [dialogDelete, setDialogDelete] = useState(false)
  const [deleting, setDeleting] = useState(false)

  // fetch 
  const [tokens, setTokens] = useState([])
  
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [token, setToken] = useState(null)

  // dialog create
  const handleClickOpen = () => {
    setName('')
    setToken(null)
    setDialogCreate(true)
  }

  const handleClose = () => {
    setDialogCreate(false)

    if (token) {
      handleFetch()
      setToken(null)
    }
  }

  const handleCreate = async () => {
    try {
      if (!token) {
        // error requerido
      }

      setCreating(true)

      const response = await create(name)
      const { result } = response

      setToken(result.token)
    } catch (error) {
      //setSuccess('')
    }

    setCreating(false)
  }

  // dialog delete
  const handleDeleteOpen = (token) => {
    setId(token.id)
    setDialogDelete(true)
  }

  const handleDeleteClose = () => {
    setDialogDelete(false)
  }

  const handleDelete = async () => {
    try {
      setDeleting(true)

      const response = await remove(id)
      const { success } = response

      console.log(success)
      
      setDialogDelete(false)
      handleFetch()
    } catch (error) {
      //setSuccess('')
    }

    setDeleting(false)
  }

  // fetch
  const handleFetch = async () => {
    try {
      const response = await list()
      const { result } = response

      setTokens(result)
    } catch (error) {
      //setSuccess('')
    }
  }

  useEffect(() => {
    handleFetch()
  }, [])

  return (
    <Container>
      <Box mt={4}>
        <Card>
          <CardHeader
            title="Tokens"
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
              >
                Add
              </Button>
            }
          />
          <CardContent>
            <Table>
              <TableHead color="primary">
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Expire</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tokens.map((token, index) => (
                  <TableRow key={index}>
                    <TableCell>{token.name}</TableCell>
                    <TableCell>{token.createdAt}</TableCell>
                    <TableCell>{token.expiresAt}</TableCell>
                    <TableCell>
                      <IconButton aria-label="delete" size="small" onClick={() => handleDeleteOpen(token)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        <Dialog open={dialogCreate} maxWidth="sm" fullWidth>
          <DialogTitle>
            Add token
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can generate a token to be used via web services. Please provide a name for the token.
            </DialogContentText>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                fullWidth
                variant="standard"
                value={name}
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
              />
              {token !== null && (
                <Box display="flex" alignItems="center" gap={1}>
                  <TextField
                    id="token"
                    name="token"
                    label="Token"
                    fullWidth
                    variant="standard"
                    value={token}
                    InputProps={{
                      readOnly: true,
                      sx: { fontSize: '0.9rem' }
                    }}
                  />
                  <IconButton size="small">
                    <ContentCopy fontSize="inherit"/>
                  </IconButton>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose} disabled={creating}>Cancel</Button>
            {token == null && (
              <Button variant="contained" onClick={handleCreate} disabled={creating}>Create</Button>
            )}
          </DialogActions>
        </Dialog>

        <Dialog open={dialogDelete} maxWidth="sm" fullWidth>
          <DialogTitle>
            Delete token
          </DialogTitle>
          <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this token?
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleDelete} disabled={deleting}>Delete</Button>
            <Button variant="outlined" onClick={handleDeleteClose} disabled={deleting}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  )
}

export default Token