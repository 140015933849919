// src/services/fileService.js
import api from './api'

const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const fixedStr = reader.result
                .replace(/=/g, '')
                .split('base64,')[1]

            resolve(fixedStr)
        };
        reader.onerror = (error) => reject(error);
    });
};

export const upload = async (file, name) => {
    try {
        const base64 = await fileToBase64(file)
        const response = await api.post('/html2pdf', { base64, name })

        console.log(response)

        if (response.data) {
            return response.data
        } else {
            throw new Error(response.data.error)
        }
    } catch (ex) {
        throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
    }
}