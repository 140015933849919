// src/services/webhookService.js
import api from './api'

export const create = async (name) => {
  try {
    const response = await api.post('/token', { name })
    
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(response.data.error)
    }
  } catch (ex) {
    throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
  }
}

export const remove = async (id) => {
  try {
    const response = await api.delete(`/token/${id}`)
    
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(response.data.error)
    }
  } catch (ex) {
    throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
  }
}

export const list = async () => {
  try {
    const response = await api.get('/token')
    
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(response.data.error)
    }
  } catch (ex) {
    throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
  }
}