// src/services/webhookService.js
import api from './api'

export const create = async (name, url) => {
  try {
    const response = await api.post('/webhook', { name, url })
    
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(response.data.error)
    }
  } catch (ex) {
    throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
  }
}

export const remove = async (id) => {
  try {
    const response = await api.delete(`/webhook/${id}`)
    
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(response.data.error)
    }
  } catch (ex) {
    throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
  }
}

export const edit = async (id, name, url) => {
  try {
    const response = await api.put(`/webhook/${id}`, { name, url })
    
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(response.data.error)
    }
  } catch (ex) {
    throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
  }
}

export const list = async () => {
  try {
    const response = await api.get('/webhook')
    
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(response.data.error)
    }
  } catch (ex) {
    throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
  }
}