// src/services/authService.js
import api from './api'

export const login = async (username, password) => {
  try {
    const response = await api.post('/login', { username, password })
    
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(response.data.error)
    }
  } catch (ex) {
    throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
  }
}

export const register = async (username, password) => {
  try {
    const response = await api.post('/register', { username, password })
    
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(response.data.error)
    }
  } catch (ex) {
    throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
  }
}

export const enroll = async () => {
  try {
    const response = await api.post('/autoenroll')
    
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(response.data.error)
    }
  } catch (ex) {
    throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
  }
}

export const recoverPassword = async (email) => {
  const response = await api.post('/recover', { email })
  return response.data
}
