// src/components/Webhooks/Webhook.js
import React, { useEffect, useState } from 'react'
import { Container, IconButton, Box, TextField, Button, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { DialogActions, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material'

import { Delete, Edit } from '@mui/icons-material'
import { create, edit, remove, list } from '../../services/webhookService'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

const Webhook = () => {
  //const [error, setError] = useState('')
  //const [success, setSuccess] = useState('')

  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')

  // dialog create
  const [dialogCreate, setDialogCreate] = useState(false)
  const [creating, setCreating] = useState(false)

  // dialog delete
  const [dialogDelete, setDialogDelete] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [editing, setEditing] = useState(false)

  // fetch 
  const [items, setItems] = useState([])

  // dialog create
  const handleCreateOpen = () => {
    setId(null)
    setName(null)
    setUrl(null)
    setDialogCreate(true)
  }

  const handleCreateClose = () => {
    setDialogCreate(false)
  }

  const handleCreate = async () => {
    try {
      setCreating(true)

      const response = await create(name, url)
      const { result } = response
    } catch (error) {
      //setSuccess('')
    }

    setCreating(false)
    setDialogCreate(false)
    handleFetch()
  }

  // dialog edit
  const handleEditOpen = (item) => {
    setId(item.id)
    setName(item.name)
    setUrl(item.url)
    setDialogCreate(true)
  }

  const handleEdit = async () => {
    try {
      setCreating(true)

      const response = await edit(id, name, url)
      const { result } = response
    } catch (error) {
      //setSuccess('')
    }

    setCreating(false)
    setDialogCreate(false)
    handleFetch()
  }

  // dialog delete
  const handleDeleteOpen = (item) => {
    setId(item.id)
    setDialogDelete(true)
  }

  const handleDeleteClose = () => {
    setDialogDelete(false)
    handleFetch()
  }

  const handleDelete = async () => {
    try {
      setDeleting(true)

      const response = await remove(id)
      const { success } = response

      setDialogDelete(false)
      handleFetch()
    } catch (error) {
      //setSuccess('')
    }

    setDeleting(false)
  }

  // fetch
  const handleFetch = async () => {
    try {
      const response = await list()
      const { result } = response

      setItems(result)
    } catch (error) {
      //setSuccess('')
    }
  }

  useEffect(() => {
    handleFetch()
  }, [])

  return (
    <Container>
      <Box mt={4}>
        <Card>
          <CardHeader
            title="Webhooks"
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateOpen}
              >
                Add
              </Button>
            }
          />
          <CardContent>
            <Table>
              <TableHead color="primary">
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Endpoint</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.url}</TableCell>
                    <TableCell>{item.createdAt}</TableCell>
                    <TableCell>
                      <IconButton size="small" onClick={() => handleDeleteOpen(item)}>
                        <Delete />
                      </IconButton>
                      <IconButton size="small" onClick={() => handleEditOpen(item)}>
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>

        <Dialog open={dialogCreate} maxWidth="sm" fullWidth>
          <DialogTitle>
            {id ? (
              <span>Edit webhook</span>
            ) : (
              <span>Add webhook</span>
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can generate a token to be used via web services. Please provide a name for the token.
            </DialogContentText>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                fullWidth
                variant="standard"
                value={name}
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                required
                id="url"
                name="url"
                label="Url"
                fullWidth
                variant="standard"
                value={url}
                autoComplete="off"
                onChange={(e) => setUrl(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={handleCreateClose}
              disabled={creating}
            >
              Cancel
            </Button>
            
            {id ? (
              <Button
                variant="contained"
                onClick={handleEdit}
                disabled={creating}
              >
                Edit
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleCreate}
                disabled={creating}
              >
                Create
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <Dialog open={dialogDelete} maxWidth="sm" fullWidth>
          <DialogTitle>
            Delete webhook
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleDelete}
              disabled={deleting}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              onClick={handleDeleteClose}
              disabled={deleting}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  )
}

export default Webhook