// src/services/balanceService.js
import api from './api'

export const purchase = async (total) => {
  try {
    const response = await api.post('/credit', { total })
    
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(response.data.error)
    }
  } catch (ex) {
    throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
  }
}

export const list = async () => {
  try {
    const response = await api.get('/credit')
    
    if (response.data.success) {
      return response.data
    } else {
      throw new Error(response.data.error)
    }
  } catch (ex) {
    throw new Error(ex.response?.data?.error || ex.message || 'An error occurred')
  }
}