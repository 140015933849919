import React, { useState } from 'react'
import {
  Box,
  Typography,
  Button,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardActions,
  Alert,
  CircularProgress,
} from '@mui/material'
import { Delete, Download } from '@mui/icons-material'
import { upload } from '../../services/fileService' // Ajusta según tu estructura de servicios

const FileUploadSection = () => {
  const [files, setFiles] = useState([])
  const [dragOver, setDragOver] = useState(false)
  const [error, setError] = useState('')

  const handleFileUpload = async (event) => {
    const newFiles = Array.from(event.target.files).map((file) => ({
      file,
      name: file.name,
      size: file.size,
      loaded: false,
      loading: true,
    }))
    setFiles((prevFiles) => [...prevFiles, ...newFiles])
    
    for (const fileObj of newFiles) {
      await handleUploadFile(fileObj)
    }
  }

  const handleFileDelete = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName))
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    setDragOver(true)
  }

  const handleDragLeave = () => {
    setDragOver(false)
  }

  const handleDrop = async (event) => {
    event.preventDefault()
    setDragOver(false)
    const newFiles = Array.from(event.dataTransfer.files).map((file) => ({
      file,
      name: file.name,
      size: file.size,
      loaded: false,
      loading: true,
    }))
    setFiles((prevFiles) => [...prevFiles, ...newFiles])
    
    for (const fileObj of newFiles) {
      await handleUploadFile(fileObj)
    }
  }

  const handleUploadFile = async (fileObj) => {
    try {
      setError('')
      const response = await upload(fileObj.file, fileObj.name)
      //console.log(response.file.name)
      
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.name === fileObj.name
            ? { 
              ...f, 
              loaded: true, 
              loading: false, 
              base64: response.file.base64,
              newName: response.file.name,
            }
            : f
        )
      )
    } catch (error) {
      setError(error.message)
      setFiles((prevFiles) =>
        prevFiles.map((f) =>
          f.name === fileObj.name ? { ...f, loading: false } : f
        )
      )
    }
  }

  const handleDownload = (base64, fileName) => {
    const link = document.createElement('a')
    link.href = `data:application/pdf;base64,${base64}`
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  /*
  <Typography variant="h4" gutterBottom>
        Upload Files
      </Typography>
      <input
        type="file"
        multiple
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        id="file-upload"
        accept=".html"
      />
      <label htmlFor="file-upload">
        <Button variant="contained" component="span">
          Select Files
        </Button>
      </label>


    <IconButton
      edge="end"
      aria-label="delete"
      onClick={() => handleFileDelete(fileObj.name)}
    >
      <Delete />
    </IconButton>
  */

  return (
    <Box sx={{ p: 3 }}>
      <input
        type="file"
        multiple
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        id="file-upload"
        accept=".html"
      />

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
      <Box
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        sx={{
          mt: 3,
          p: 2,
          border: '1px dashed #b4b4b4',
          borderRadius: '4px',
          backgroundColor: dragOver ? '#f0f0f0' : '#ffffff',
          textAlign: 'center',
          minHeight: '150px',
        }}
      >
        <Typography variant="h3" sx={{ mb: 2 }}>
          HTML to PDF
        </Typography>

        <Typography variant="h6" color="secondary" sx={{ mb: 2 }}>
          Fast and simple HTML to PDF converter
        </Typography>

        <label htmlFor="file-upload">
          <Button variant="contained" component="span">
            Select Files
          </Button>
        </label>

        <Typography variant="body2" color="secondary" sx={{ mt: 1, mb: 2 }}>
          or drag & drop
        </Typography>

        <Grid container spacing={2} sx={{ mt: 4 }}>
          {files.map((fileObj) => (
            <Grid item xs={12} sm={4} md={4} key={fileObj.name}>
              <Card>
                <CardContent>
                  <Typography variant="body1" noWrap>
                    {fileObj.name}
                  </Typography>
                  <Typography variant="body3" color="secondary">
                    Size: {(fileObj.size / 1024).toFixed(2)} KB
                  </Typography>
                </CardContent>
                <CardActions>
                  {fileObj.loading ? (
                    <CircularProgress size={24} />
                  ) : fileObj.loaded ? (
                    <IconButton
                      variant="contained"
                      onClick={() => handleDownload(fileObj.base64, fileObj.newName)}
                    >
                      <Download />
                    </IconButton>
                  ) : null}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

export default FileUploadSection
