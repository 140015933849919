// src/components/LandingPage/LandingPage.js
import React, { useEffect, useRef, useContext } from 'react'
import HeroSection from './HeroSection'
import FeaturesSection from './FeaturesSection'
import PricingSection from './PricingSection'
import WebhookIntegrationSection from './WebhookIntegrationSection'
import FileUploadSection from '../FileUpload/FileUploadSection'
import { enroll } from '../../services/authService'

import { AuthContext } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'


const LandingPage = () => {
  const pricingRef = useRef(null)
  const { login, check } = useContext(AuthContext)
  const navigate = useNavigate()

  const handleGetStartedClick = () => {
    pricingRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  /*
      <HeroSection onGetStartedClick={handleGetStartedClick} />
      <div ref={pricingRef}>
        <PricingSection />
      </div>
  */

  // fetch
  const handleEnroll = async () => {

    if (!check()) {
      try {
        const response = await enroll()
        
        login(response.result)
        navigate('/')
        
      } catch (error) {
        console.log('fail auto enroll')
      }
    }
  }

  useEffect(() => {
    handleEnroll()
  }, [])

  return (
    <div>
      
      <FileUploadSection />
      <FeaturesSection />
      <WebhookIntegrationSection />
    </div>
  )
}

export default LandingPage
