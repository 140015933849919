// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react'

export const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    // Aquí puedes comprobar si hay un token guardado en localStorage 
    // o cookies y actualizar el estado del usuario.
    const storedUser = localStorage.getItem('token')
    
    if (storedUser) {
      // JSON.parse(storedUser)
      setUser({ name: 'john denver' })
    }
  }, [])

  const login = (userData) => {
    const { token } = userData
    
    setUser(userData)
    localStorage.setItem('token', token)
  }

  const logout = () => {
    setUser(null)
    localStorage.removeItem('token')
  }

  const check = () => {
    const token = localStorage.getItem('token')

    console.log(token)

    return token != null
  }

  return (
    <AuthContext.Provider value={{ user, login, logout, check }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
