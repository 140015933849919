// src/pages/BalancePage.js
import React from 'react';
import Balance from '../components/Balance/Balance';

const BalancePage = () => {
  return (
    <div>
      <Balance />
    </div>
  );
};

export default BalancePage;
