import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, TextField, Button, Alert, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { purchase as purchaseService, list as listService } from '../../services/balanceService';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

const Balance = () => {
  const [balance, setBalance] = useState(0);
  const [creditInput, setCreditInput] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [history, setHistory] = useState([]);

  const fetchBalance = async () => {
    try {
      const response = await listService();
      const { total, balances } = response.result;

      setBalance(total);
      setHistory(balances);
    } catch (error) {
      setError('');
    }
  };

  const handleCreditInputChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(value)) {
      setCreditInput(value);
    }
  };

  const handleAddCredits = async () => {
    const creditsToAdd = parseFloat(creditInput);
    if (isNaN(creditsToAdd) || creditsToAdd <= 0) {
      setError('Please enter a valid amount of credits to add.');
      setSuccess('');
      return;
    }

    try {
      const response = await purchaseService(creditsToAdd);
      const { balance, total } = response.result;

      setBalance(total);
      setCreditInput('');
      setError('');
      setSuccess(`Successfully added ${creditsToAdd} credits.`);
      setHistory([balance, ...history]);
    } catch (error) {
      setError('Failed to add credits.');
      setSuccess('');
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  const getTransactionIcon = (concept) => {
    if (concept === "buy" || concept === "courtesy credits") {
      return <ArrowUpward sx={{ color: 'green' }} />;
    }
    return <ArrowDownward sx={{ color: 'red' }} />;
  };

  return (
    <Container>
      <Box mt={4}>
        <Box mb={5}>
          <Box textAlign="center" sx={{ width: 300, margin: 'auto' }}>
            <Typography variant="h5">Your Balance</Typography>
            <Typography variant="h4" color="primary">{balance} Credits</Typography>
            <Box mt={4}>
              <TextField
                label="Add Credits"
                variant="outlined"
                value={creditInput}
                onChange={handleCreditInputChange}
                fullWidth
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />
              <Button variant="contained" color="primary" onClick={handleAddCredits} sx={{ mt: 2 }}>
                Add Credits
              </Button>
            </Box>
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {success}
              </Alert>
            )}
          </Box>
        </Box>
          
        <Card>
          <CardHeader
            title="Credit history"
          />
          <CardContent>
            <Table>
              <TableHead color="primary">
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Concept</TableCell>
                  <TableCell>Credits</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((transaction, index) => (
                  <TableRow key={index}>
                    <TableCell>{transaction.createdAt}</TableCell>
                    <TableCell>{transaction.concept}</TableCell>
                    <TableCell>{transaction.used}</TableCell>
                    <TableCell>{transaction.total}</TableCell>
                    <TableCell>{getTransactionIcon(transaction.concept)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default Balance;
